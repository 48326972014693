import { useQuery } from 'react-query';
import axios from 'axios';
import { apiBaseUrl } from 'config';
import { useAppSettings } from '../Contexts/AppSettingsProvider';
import { appLangCode } from '../helpers';
import { useAuth } from '../Contexts/AuthProvider';

const getDateTypes = async (lang) => {
    const {data} = await axios(`${apiBaseUrl}/data/date-types`, {
        headers: { 'Accept-Language': appLangCode(lang) },
    })
    return data;
}

export default function useDateTypes() {
    const { userLoaded } = useAuth();
    const { appLanguage, loading } = useAppSettings();
    return useQuery(['date-types', appLanguage], () => getDateTypes(appLanguage,), {
        initialData: [],
        keepPreviousData: true,
        enabled: !loading && !!appLanguage && userLoaded,
        refetchOnWindowFocus: false,
    })
}
