import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { Button, Dialog, TextField, Popover, MenuItem, Checkbox } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { filterArray } from 'utils/functions';
import DmsService from 'Services/DMS/dms-api.service';
import useTranslation from 'Hooks/useTranslation';
import JobLabel from './JobLabel';
import debounce from "lodash.debounce";
import CircularProgress from "@material-ui/core/CircularProgress";

const ChangeJobModal = ({ jobs, title, isOpen, onCancel, onSave, onSelectionChanged, type, setCurrentJobUnits, currentJob }) => {
    const [value, setValue] = useState(0);
    const [search, setSearch] = useState('');
    const [lastJobsCount, setLastJobsCount] = useState(0);
    const translation = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
     const [pagedJobs, setPagedJobs] = useState([]);
      const [licenses, setLicenses] = useState(null);
      const [selectedLicenses, setSelectedLicenses] = useState([]);
      const listRef = useRef(null);
        const [isLoading, setIsLoading] = useState(false);
    const handleChange = event => {
        setValue(+event.target.value);
    }

    const allowedJobs = useMemo(() => {
      if (!Array.isArray(jobs)) return [];
      return jobs.filter((job) => !job.isDeleting);
    }, [jobs]);
    
      useEffect(() => {
        if (isOpen) {
          setPagedJobs([]); 
          setLastJobsCount(0);
        }
      }, [selectedLicenses, isOpen, search]);
      
    
      useEffect(() => {
        if (isOpen) {
          setIsLoading(true);
          const debouncedFetchJobs = debounce(() => {
            DmsService.getJobsShortPaged(0, 40, selectedLicenses, search)
              .then((data) => {
                setPagedJobs(data);
                setIsLoading(false);
              })
              .catch((err) => {
                console.error(err);
              });
          }, 300);
    
          debouncedFetchJobs();
          return () => {
            debouncedFetchJobs.cancel();
          };
        }
      }, [isOpen, selectedLicenses, search]);
    
      useEffect(() => {
        if (isOpen) {
          DmsService.getLicenses()
            .then((data) => setLicenses(data))
            .catch((err) => console.error(err));
        }
      }, [isOpen]);
    
      const displayedJobs = useMemo(() => {
        if (!pagedJobs.length || !allowedJobs.length) return [];
        return pagedJobs.filter((job) =>
          allowedJobs.some((allowedJob) => allowedJob.id === job.id)
        );
      }, [pagedJobs, allowedJobs]);
    
      const filteredJobs = useMemo(() => {
        if (search && search.trim() !== "") {
          return filterArray(allowedJobs, search);
        }
        return displayedJobs;
      }, [allowedJobs, displayedJobs, search]);

    useEffect(() => {
        if (!currentJob) return;

        setValue(currentJob?.id)
    }, [currentJob]);



    useEffect(() => {
        if (!allowedJobs || allowedJobs.length <= lastJobsCount)
            return;
        setValue(allowedJobs[0].id);
        setLastJobsCount(allowedJobs.length);
    }, [allowedJobs, lastJobsCount]);

    /**
     * Flow for selecting first visible job
     */
    useEffect(() => {

        if (filteredJobs.length) {
            if (!filteredJobs.find(job => job.id === value)) {
                setValue(filteredJobs[0].id);
            }
        } else {
            setValue('');
        }
    }, [filteredJobs, search, value, onSelectionChanged, isOpen]);

    useEffect(() => {
        const fetchJobById = async (jobId) => {
            if (jobId && allowedJobs && allowedJobs.length) {
                let response = await DmsService.getJobsById(jobId);
                if (response) {
                    const job = allowedJobs.find(x => x.id === jobId);
                    job.units = response.units;
                    setCurrentJobUnits(response.units);
                }
            }
        };
        if (value && isOpen && type !== 'oneJobPassword') {
            fetchJobById(value).then();
        }

    }, [value, setCurrentJobUnits, allowedJobs, isOpen, type]);

    const handleSelectLicense = (license) => {
        setSelectedLicenses((prevSelected) => {
          if (prevSelected.includes(license.id)) {
            return prevSelected.filter((id) => id !== license.id);
          } else {
            return [...prevSelected, license.id];
          }
        });
      };
    
      const licensesData = licenses?.map((license) => ({
        id: license,
        license: license,
      }));
    
      const selectedLicensesLabel =
        selectedLicenses.length > 0
          ? licensesData
              .filter((l) => selectedLicenses.includes(l.id))
              .map((l) => l.license)
              .join(", ")
          : "";
    
      const popoverOpen = Boolean(anchorEl);
      const popoverId = popoverOpen ? "license-popover" : undefined;

      const handleOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClosePopover = () => {
        setAnchorEl(null);
      };
    
      const loadMoreJobs = useCallback(() => {
        if (isLoading) return;
        setIsLoading(true);
        const skip = pagedJobs.length;
        DmsService.getJobsShortPaged(skip, 40, selectedLicenses, search)
          .then((data) => {
            if (data && data.length > 0) {
              setPagedJobs((prevJobs) => [...prevJobs, ...data]);
            }
          })
          .catch((err) => console.error(err))
          .finally(() => setIsLoading(false));
      }, [isLoading, pagedJobs.length, selectedLicenses, search]);

       useEffect(() => {
          const container = listRef.current;
          if (!container) return;
      
          const handleScroll = () => {
            const threshold = 100;
            if (
              container.scrollTop + container.clientHeight >=
              container.scrollHeight - threshold
            ) {
              loadMoreJobs();
            }
          };
      
          container.addEventListener("scroll", handleScroll);
          return () => container.removeEventListener("scroll", handleScroll);
        }, [loadMoreJobs]);

    return (
        <Dialog open={isOpen} className="respModal">
            <div className="modalTitleHolder mb16">
                <h1 className="modalTitle text-center">{title || "Select job"}</h1>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="search"
                label={translation.dashboardSearchJobLabel}
                type="text"
                value={search}
                onChange={e => setSearch(e.target.value)}
            />
            <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="license"
          label="Filter by license"
          value={selectedLicensesLabel}
          onClick={handleOpenPopover}
          InputProps={{
            readOnly: true,
          }}
        />

        <Popover
          id={popoverId}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {licensesData?.map((license) => (
            <MenuItem
              key={license.id}
              value={license.id}
              onClick={() => handleSelectLicense(license)}
              className="filterMenuItem"
              style={{ width: "325px" }}
            >
              <Checkbox
                size="small"
                id={`checkbox-${license.id}`}
                color="default"
                checked={selectedLicenses.includes(license.id)}
              />
              {license.license}
            </MenuItem>
          ))}
        </Popover>
            </div>
            <div className="checkboxListHolder mt8">
                <div className="labelLike">{translation.dashboardJobsLabel}</div>
                <div className="labelLike licence">Licence</div>
                <div className="labelLike dateStart">{translation.dashboardStartTimeLabel}</div>
                <div className="labelLike dateStop">{translation.dashboardStopTimeLabel}</div>
                <div className="checkboxListContainer"
                ref={listRef}
                // style={{
                //   height: '650px',
                //   overflowY: "auto",
                //   position: "relative",
                // }}
                >
                    <RadioGroup
                        aria-label="jobs"
                        name="jobs"
                        value={value}
                        onChange={handleChange}
                    >
                        {filteredJobs &&
                            filteredJobs.map(job => {
                                return (
                                    <FormControlLabel
                                        disabled={job?.isDeleting}
                                        key={job.id}
                                        value={job.id}
                                        label={<JobLabel label={job.name} job={job} />}
                                        control={<Radio color="default" />} />
                                );
                            })
                        }
                    </RadioGroup>
                </div>
                {isLoading && (
                          <div
                            style={{
                              position: "absolute",
                              top: "40%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              zIndex: 10,
                            }}
                            className="loaderHolder"
                          >
                            <CircularProgress />
                          </div>
                        )}
            </div>
            <div className="btnHolder end mt22 fixedWidthBtn">
                <Button
                    onClick={onCancel}
                    variant="outlined">
                    {translation.cancelButtonLabel}
                </Button>
                <Button
                    onClick={() => onSave(value, false)}
                    disabled={!value}
                    variant="contained"
                    color="primary"
                >
                    {translation.saveButtonLabel}
                </Button>
            </div>
        </Dialog>
    );
};
export default ChangeJobModal;
