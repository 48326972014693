import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
    useRef
  } from 'react';
  import jwtDecode from 'jwt-decode';
  import { usernameFromFullName } from 'utils/functions';
  import ApiService from 'Services/Api.service';
  import { useDispatch, useSelector } from 'react-redux';
  import { addBox, setCurrentCompany, setCurrentJob } from 'Store/modules/dashboard/actions';
  import { v1 as uuid } from 'uuid';
  import JobsManagerSignalr from 'Components/SignalrSubscribes/JobsManagerSignalr.component';
  import { buildUserHub } from 'Components/SignalrSubscribes/signalRHubs';
  import DmsApiService from 'Services/DMS/dms-api.service';
  import { useAppSettings } from 'Contexts/AppSettingsProvider';
  import axios from 'axios';
  import { useQuery } from 'react-query';
  import { apiBaseUrl } from '../config';
  import User from '../Models/User';
  import { clearStoredCompany } from '../storage/company-storage';
  import { clearStoredDepartment } from '../storage/department-storage';
  import { setStoredLang } from '../storage/lang-storage';
  import { apiAuthBaseUrl } from 'config';
  import authEndpoints from 'Services/endpoints/auth.endpoints';
  import qs from 'qs';

  const MAX_SAFE_TIMEOUT = 2147483647;
  const AuthContext = createContext({});
  AuthContext.displayName = 'AuthContext';

  const STORAGE_ACCESS_TOKEN_KEY = '_t';

  export const AuthProvider = ({ children }) => {
    const currentCompany = useSelector((state) => state.dashboard.currentCompany);
    const [user, setUser] = useState(null);
    const [isSignedFlow, setIsSignedFlow] = useState(false);
    const [userLoading, setUserLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [accessToken, setAccessToken] = useState('');
    const [refreshToken, setRefreshToken] = useState('');
    const [isDashboardWindow, setIsDashboardWindow] = useState(false);
    const [id, setId] = useState();
    const [win, setWin] = useState({ id: uuid(), parentId: null });
    const [dbSizeInfo, setDbSizeInfo] = useState(null);

    const dispatch = useDispatch();
    const [hubConnection, setHubConnection] = useState(null);
    const [, setConnectionState] = useState(null);
    const { setAppTheme, appLanguage, setAppLanguage, setUserBoundChannelColors } = useAppSettings();

    const userLoaded = useMemo(() => {
      if (!user) return false;
      if (!user.isSuperuser()) return true;
      return user.isSuperuser() && !!currentCompany;
    }, [user, currentCompany]);

    const isChildWindow = useMemo(() => win.id && !!win.parentId, [win.id, win.parentId]);

    const dbSystemRequest = useQuery(
      ['dbSystem', currentCompany, user],
      async () => {
        const options = !user.isSuperuser() ? {} : {
              headers: { 'CompanyId': currentCompany?.id },
            };
        return await axios.get(`${apiBaseUrl}/system/database`, options);
      },
      { keepPreviousData: true, enabled: !!userLoaded, refetchOnWindowFocus: false, }
    );

    useEffect(() => {
      if (dbSystemRequest.isSuccess) {
        setDbSizeInfo(dbSystemRequest.data.data);
      }
    }, [dbSystemRequest.isSuccess, dbSystemRequest.data]);

    useEffect(() => {
      if (user && user.isSuperuser() && currentCompany) {
        axios.defaults.headers.common['CompanyId'] = currentCompany.id;
      }
    }, [currentCompany, user]);

    useEffect(() => {
      const theme = window.sessionStorage.getItem('theme');
      setAppTheme(theme);
      localStorage.setItem('theme', theme);
      ApiService.init(appLanguage);
    }, [setAppTheme, appLanguage]);

    const createHubConnection = useCallback(async () => {
      if (!user) return;
      if (user && user.isSuperuser() && !currentCompany) return;

      const hubConnect = buildUserHub(accessToken, currentCompany?.id);
      try {
        await hubConnect.start();
        console.log('win hub started...');
        setConnectionState(hubConnect.state);
        hubConnect.on('Drop', (data) => {
          localStorage.removeItem(STORAGE_ACCESS_TOKEN_KEY);
          localStorage.removeItem('filterInfo');
          localStorage.removeItem('_rt');
          setIsAuthenticated(false);
          setAccessToken('');
          setUser(null);
          setWin({ id: win.id, parentId: null });
          ApiService.removeAuthHeader();
          if (hubConnect && hubConnect.state === 'Connected') {
            hubConnect.stop();
          }
        });
        hubConnect.on('AccessTokenUpdated', (accessToken) => { 
          console.log('AccessTokenUpdated', accessToken);
          accessToken && setAccessToken(accessToken);
          localStorage.setItem(STORAGE_ACCESS_TOKEN_KEY, accessToken);
          ApiService.setAuthHeader(accessToken); 
        });
        hubConnect.on('ParentTabClosed', () => { window.close(); });
        hubConnect.on('ThemeChanged', (theme) => {
          if (isChildWindow) { setAppTheme(theme === 1 ? 'Dark' : 'Light'); }
        });
        const windowData = { TabId: win.id, ParentTabId: win.parentId === win.id ? null : win.parentId };
        await hubConnect.invoke('Subscribe', windowData);
        hubConnect.onclose((error) => { console.log('win hub closed...'); setConnectionState(hubConnect.state); });
        hubConnect.onreconnecting(async () => {
          console.log('users reconnecting...');
          setConnectionState(hubConnect.state);
        });
        hubConnect.onreconnected(async () => {
          console.log('users reconnected');
          setConnectionState(hubConnect.state);
        });
      } catch (err) {
        console.log('Error while establishing connection: ', err);
      }
      setHubConnection(hubConnect);
    }, [user, currentCompany, accessToken, setAccessToken, win.id, win.parentId, isChildWindow, setAppTheme]);

    const versionEndpoint = () => ({
      url: `${apiAuthBaseUrl}/home/version`,
      method: 'get',
    });

    const fetchVersion = () => {
      return axios(versionEndpoint())
        .then((response) => {
          const storedVersion = localStorage.getItem('version');
          const pageReloaded = localStorage.getItem('pageReloaded');
          if (!storedVersion) {
            localStorage.setItem('version', response.data.version);
          } else if (storedVersion !== response.data.version && !pageReloaded) {
            localStorage.setItem('version', response.data.version);
            localStorage.setItem('pageReloaded', true);
            window.location.reload(true);
            localStorage.removeItem('_t');
          } else {
            localStorage.removeItem('pageReloaded');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };

    useEffect(() => {
      if (isAuthenticated) {
        fetchVersion();
      }
    }, [isAuthenticated]);

    const refreshTimerRef = useRef(null);

    const setAuthData = useCallback(
      async (token, rt) => {
        if (!token) {
          localStorage.removeItem(STORAGE_ACCESS_TOKEN_KEY);
          localStorage.removeItem('_rt');
          setIsAuthenticated(false);
          setAccessToken('');
          setRefreshToken('');
          setUser(null);
          ApiService.removeAuthHeader();
          if (refreshTimerRef.current) {
            clearTimeout(refreshTimerRef.current);
            refreshTimerRef.current = null;
          }
          if (hubConnection && hubConnection.state === 'Connected') {
            hubConnection.stop();
          }
          return;
        }
        try {
          if (accessToken !== token) {
            const decodedToken = jwtDecode(token);
            setAccessToken(token);
            setRefreshToken(rt);
            setIsAuthenticated(true);
            setUser(
              new User({
                name: decodedToken.full_name
                  ? usernameFromFullName(decodedToken.full_name)
                  : decodedToken.name,
                role: decodedToken.role,
                permissions: Array.isArray(decodedToken.ui_permissions)
                  ? [...decodedToken.ui_permissions]
                  : [decodedToken.ui_permissions],
                serverPermissions: Array.isArray(decodedToken.permissions)
                  ? [...decodedToken.permissions]
                  : [decodedToken.permissions],
                companyId: decodedToken.company_id,
                defaultCompanyId: decodedToken?.default_company_id,
                isOneJobPassword: decodedToken.type === 'ojp',
              })
            );
            ApiService.setAuthHeader(token);
          }
          if ((!hubConnection || hubConnection.state === 'Disconnected') && win.id) {
            createHubConnection().then();
            return;
          }
        } catch (e) {
          localStorage.removeItem(STORAGE_ACCESS_TOKEN_KEY);
          localStorage.removeItem('_rt');
          throw new Error('Token parsing error');
        }
      },
      [accessToken, hubConnection, win.id, createHubConnection]
    );

    const logoutHandler = useCallback(() => {
      if (refreshTimerRef.current) {
        clearTimeout(refreshTimerRef.current);
        refreshTimerRef.current = null;
      }
      setAuthData();
      setWin({ id: win.id, parentId: null })
      dispatch(setCurrentCompany(null));
      clearStoredCompany();
      clearStoredDepartment();
    }, [setAuthData, dispatch, win.id]);

    const refreshAccessTokenProactive = useCallback(async () => {
      const storedRefreshToken = refreshToken || localStorage.getItem('_rt');
      const currentWinId = win.id;
  
      if (storedRefreshToken) {
        try {
          const refreshResponse = await axios({
            url: authEndpoints.getToken().url,
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'tabId': currentWinId },
            data: qs.stringify({
              grant_type: 'refresh_token',
              client_id: '9802c75a-bfe3-4821-94c0-5abe6fecba01',
              client_secret: 'BLiov3M57RmtbwZF8SvbXZBsqyMjAU0/ixLKv1QPLRCOpfvtxdBnVhocAr/GAoL4SErAHTrRoI9+NYmkKqCLtFxhaXXzKwEAYb4HjKzi8+xynEUrRujoUYSv1cXhyGuFJAe2BfDYFUmh8Rndd1JLtO/69SIeJx8Teuj3uwxki0UF3lZlTq20j5xVyLuhH9tZ3SDEQg+6Qsy3Zn6t3/aFFpSHZGBsNzdktqyWjN8az8MOiK/pRLBkLME4gJlQsRfOmAzzJuP3CSP2ZQbCfu3cEIenpUIGEj6+J34tW5bcc2mqDE+RVcvOXG3pyDRRSLk7t0P49JYnXbwdY+1/fZGkSg==',
              refresh_token: storedRefreshToken,
            }),
          });
  
          if (refreshResponse.status === 200) {
            const tokenData = refreshResponse.data;
            const newRefreshToken = tokenData.refresh_token || storedRefreshToken;
  
            await setAuthData(tokenData.access_token, newRefreshToken);
            ApiService.setAuthHeader(tokenData.access_token);
            setIsSignedFlow(true);
            localStorage.setItem(STORAGE_ACCESS_TOKEN_KEY, tokenData.access_token);
            localStorage.setItem('_rt', newRefreshToken);
  
            if (refreshTimerRef.current) {
              clearTimeout(refreshTimerRef.current);
              refreshTimerRef.current = null;
            }
          
            let refreshTimeMs = (tokenData.expires_in - 60) * 1000;
         
            if (refreshTimeMs > MAX_SAFE_TIMEOUT) {
              refreshTimeMs = MAX_SAFE_TIMEOUT;
            }
  
            refreshTimerRef.current = setTimeout(() => {
              refreshAccessTokenProactive();
            }, refreshTimeMs);
  
            return true;
          }
        } catch (error) {
          console.error('Refresh token error:', error.message);
          logoutHandler();
          return false;
        }
      } else {
        logoutHandler();
        return false;
      }
    }, [refreshToken, win.id, setAuthData, logoutHandler]);
  
    const loginHandler = useCallback(
      (tokenData) => {
        setAuthData(tokenData.access_token, tokenData.refresh_token);
        setIsSignedFlow(true);
        localStorage.setItem(STORAGE_ACCESS_TOKEN_KEY, tokenData.access_token);
        localStorage.setItem('_rt', tokenData.refresh_token);
  
        if (refreshTimerRef.current) {
          clearTimeout(refreshTimerRef.current);
          refreshTimerRef.current = null;
        }
        
        let refreshTimeMs = (tokenData.expires_in - 60) * 1000;
       
        if (refreshTimeMs > MAX_SAFE_TIMEOUT) {
          refreshTimeMs = MAX_SAFE_TIMEOUT;
        }
  
        refreshTimerRef.current = setTimeout(() => {
          refreshAccessTokenProactive();
        }, refreshTimeMs);
      },
      [setAuthData, refreshAccessTokenProactive]
    );

    useEffect(() => {
      const authInterceptor = axios.interceptors.response.use(
        (response) => response,
        async (error) => {
          const originalRequest = error.config;
          if (
            error.response &&
            error.response.status === 401 &&
            !originalRequest._retry
          ) {
            originalRequest._retry = true;

            const refreshSuccess = await refreshAccessTokenProactive();
            if (refreshSuccess) {
              originalRequest.headers['Authorization'] =
                'Bearer ' + localStorage.getItem(STORAGE_ACCESS_TOKEN_KEY);
              return axios(originalRequest);
            }
          }

          if (
            error.response &&
            error.response.status === 403
          ) {
            logoutHandler();
            localStorage.removeItem(STORAGE_ACCESS_TOKEN_KEY);
            localStorage.removeItem('_rt');
          }

          return Promise.reject(error);
        }
      );
      return () => {
        axios.interceptors.response.eject(authInterceptor);
      };
    }, [refreshAccessTokenProactive, win.id]);

    const setUserLanguage = useCallback(
      (language) => {
        setAppLanguage(language);
        if (!isChildWindow) {
          DmsApiService.setLanguage(win.id, language).then();
          setStoredLang(language);
        }
      },
      [setAppLanguage, isChildWindow, win.id]
    );

    const setUserTheme = useCallback(
      (theme) => {
        setAppTheme(theme);
        if (!isChildWindow) {
          DmsApiService.setTheme(win.id, theme).then();
        }
      },
      [setAppTheme, win.id, isChildWindow]
    );

    const setBoundChannelColors = useCallback(
      (boundChannelColors) => {
        setUserBoundChannelColors(boundChannelColors);
      },
      [setUserBoundChannelColors]
    );

    useEffect(() => {
      const fetchUserSettings = () => {
        DmsApiService.getSettings().then((response) => {
          if (response && response.data) {
            const { language, theme } = response.data;
            window.sessionStorage.setItem('theme', theme);
            setAppTheme(theme);
            setAppLanguage(language);
          }
        });
      };
      if (isAuthenticated) {
        fetchUserSettings();
      }
    }, [isAuthenticated, setAppLanguage, setAppTheme]);

    useEffect(() => {
      const at = localStorage.getItem(STORAGE_ACCESS_TOKEN_KEY);
      if (at) {
        setAuthData(at);
      }
      setUserLoading(false);
    }, [setAuthData]);

    useEffect(() => {
      if (!isAuthenticated) return;
      if (id) {
        const newWinStorageKey = `dashboard-${id}`;
        const localStorageData = localStorage.getItem(newWinStorageKey);
        if (!localStorageData) {
          console.log('No any dashboard data in storage');
          return;
        }
        const dashboardInfo = JSON.parse(localStorageData);
        localStorage.removeItem(newWinStorageKey);
        const { job, box, parentDashboardId, company } = dashboardInfo;
        if (job) dispatch(setCurrentJob(job));
        if (company) dispatch(setCurrentCompany(company));
        if (box) {
          if (Array.isArray(box)) {
            box.forEach((b) => dispatch(addBox(b)));
          } else {
            dispatch(addBox(box));
          }
        }
        setWin({
          id: win.id,
          parentId: parentDashboardId ? parentDashboardId : null,
        });
      }
    }, [id, dispatch, isAuthenticated, win.id]);

    // useEffect(() => {
    //   if (isDashboardWindow) return;
    //   setWin({ id: uuid(), parentId: null });
    // }, [isDashboardWindow]);

    const initDashboardWindow = useCallback((dashboardId) => {
      setIsDashboardWindow(true);
      if (dashboardId) setId(dashboardId);
    }, []);

    useEffect(() => {
      const handleStorageChange = (event) => {
        if (event.key === STORAGE_ACCESS_TOKEN_KEY && event.newValue) {
          const newToken = event.newValue;
          if (newToken !== accessToken) {
            setAccessToken(newToken);
            ApiService.setAuthHeader(newToken);
            setIsAuthenticated(true);
          }
        }
    
        if (event.key === '_rt' && event.newValue) {
          setRefreshToken(event.newValue);
        }
      };
    
      window.addEventListener('storage', handleStorageChange);
    
      return () => window.removeEventListener('storage', handleStorageChange);
    }, [accessToken]);

    return (
      <AuthContext.Provider
        value={{
          isLoggedIn: isAuthenticated,
          currentUser: user,
          token: accessToken,
          login: loginHandler,
          logout: logoutHandler,
          setUserLanguage,
          setUserTheme,
          setBoundChannelColors,
          initDashboardWindow,
          win,
          setWin,
          dbSizeInfo,
          userLoaded,
          currentCompany,
          isSignedFlow
        }}
      >
        {!userLoading && children}
        {<JobsManagerSignalr isSuperUser={user && user.role === 'superuser'} />}
      </AuthContext.Provider>
    );
  };

  export const useAuth = () => useContext(AuthContext);
