import React, { useState, useMemo } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { clearDashboard, fetchJobsSuccess, setCompanies, setCurrentCompany } from 'Store/modules/dashboard/actions';
import { connect } from 'react-redux';
import { setDepartments, setEmployees } from 'Store/modules/mms/actions';
import useTranslation from 'Hooks/useTranslation';
import 'moment/min/locales';
import 'react-circular-progressbar/dist/styles.css';
import { setStoredCompany } from '../storage/company-storage';

const Header = ({
                    clearDashboard,
                    companies,
                    currentCompany,
                    setCurrentCompany,
                    setDepartments,
                    setEmployees,
                }) => {

    const translation = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');
   
    const filteredCompanies = useMemo(() => {
        if (!companies || !searchQuery) return companies;
        return companies.filter(company => 
            company.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [companies, searchQuery]);

    return (
        <div style={{ width: '200px' }}>
            {currentCompany && (
                <Autocomplete
                    options={filteredCompanies || companies || []}
                    getOptionLabel={(option) => option.name}
                    value={currentCompany}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            setStoredCompany(newValue.id);
                            setCurrentCompany(newValue);
                            clearDashboard();
                            setDepartments(null);
                            setEmployees(null);
                            window.location.reload();
                        }
                    }}
                    className='changeCompanyHeader'
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            margin="none"
                            label={translation.headerCompanyLabel}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    )}
                    renderOption={(option) => (
                        <MenuItem 
                            value={option}
                            className="filterMenuItem"
                            style={{ padding: '0px 0px' }}
                        >
                            {option.name}
                        </MenuItem>
                    )}
                />
            )}
        </div>
    );
};

const mapDispatchToProps = {
    clearDashboard,
    setCurrentCompany,
    fetchJobsSuccess,
    setCompanies,
    setDepartments,
    setEmployees,
};

const mapStateToProps = ({ dashboard }) => ({
    companies: dashboard.companies,
    currentCompany: dashboard.currentCompany,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
